import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
// import "../css/@wordpress/block-library/build-style/style.css"
// import "../css/@wordpress/block-library/build-style/theme.css"

// import Bio from "../components/bio"
import Layout from "../component/layout"
import Seo from "../component/seo"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }

  return (
    <Layout title={parse(post.title)} captions={`Posted on ${post.date}`}>
      <Seo title={post.title} description={post.excerpt} />
      {/* <header className="global-header mb-3">
        <div className="container">
          <h1 itemProp="headline">{parse(post.title)}</h1>
        </div>
      </header> */}
      <div className="container my-4">
        <article
          className="blog-post clearfix"
          itemScope
          itemType="http://schema.org/Article"
        >
          {/* <header className="mb-3">
            <h1 itemProp="headline">{parse(post.title)}</h1>
            <small>Posted on {post.date}</small>
          </header> */}
          {/* if we have a featured image for this post let's display it */}
          {featuredImage?.data && (
            <div className="mb-4">
              <GatsbyImage
                image={featuredImage.data}
                alt={featuredImage.alt}
              />
            </div>
          )}

          {!!post.content && (
            <section itemProp="articleBody">{parse(post.content)}</section>
          )}
          {/* <hr />
          <footer>
            <Bio />
          </footer> */}
        </article>
        <div className="blog-post-nav d-flex flex-wrap justify-content-between">
          {previous && (
            <Link to={previous.uri} rel="prev" className="btn btn-blue btn-sm my-2">
              ← {parse(previous.title)}
            </Link>
          )}
          {next && (
            <Link to={next.uri} rel="next" className="btn btn-blue btn-sm my-2">
            {parse(next.title)} →
            </Link>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost( id: { eq: $id }) 
    {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: DOMINANT_COLOR
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
    previous: wpPost(
      id: { eq: $previousPostId }) 
      {
      uri
      title
    }
    next: wpPost(
      id: { eq: $nextPostId }
      ) {
      uri
      title
    }
  }
`
